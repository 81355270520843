.card-page {
  background-image: url(../../logos/background.png);
  height: 100%;
  background-repeat: no-repeat;
  width: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  background-size: 100% 100%;
  padding: 60px 30px 30px 30px;
}

.header-wrapper {
  padding-left: 20px;
}

.page-wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

main {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.card-info-main {
  background-color: #9EB9FF;
  transform: rotate(-3deg);
  height: 250px;
  width: calc(100vw * 1.1);
  align-self: center;
  display: flex;
  margin-top: 12%;
}

.card-info {
  padding: 20px;
  padding-left: calc(1vw * 7.2);
  box-sizing: border-box;
  background-color: #0001FB;
  height: 200px;
  width: calc(100vw * 1.1);
  display: flex;
  align-self: center;
  flex-direction: column;
  gap: 8px;
}

.card-action {
  width: 150px;
  height: 40px;
  transform: rotate(2deg);
  margin-left: -12px;
}

.card-title {
  font-family: Comfortaa;
  font-size: 20px;
  font-weight: 400;
  display: inline-block;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: left;
  transform: rotate(2deg);
  color: #fff;
}