.header {
  text-align: center;
  border-radius: 0 0 5px 5px;
  z-index: 1;
  font-size: 30px;
  padding: 20px 0;
}

.header-wrapper {
  z-index: 1;
}

.header-previous {
  display: none;
  background-color: inherit;
  border: 0;
}

@media (max-width: 600px) {

  .header-previous {
    display: block;
  }

  .header {
    display: block;
    text-align: start;
    position: absolute;
    top: 0;
  }
}
