@font-face {
  font-family: 'Comfortaa';
  src: local('MyFont'), url('./fonts/BillabongCyr.ttf') format('truetype');
}

.app {
  height: fit-content;
  width: 100vw;
  height: 100%;
  font-family: 'Regular';
  font-weight: 100;
  position: relative;
  box-sizing: border-box;
}

.header-previous {
  display: none;
  background-color: #fff;
  border: 0;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-direction: column;
}

.title {
  font-size: 34px;
  margin: 20px 0;
  font-weight: 500;
}

.help_text {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  font-size: 16px;
  width: 78%;
}

.main-page-img {
  align-self: center;
}

.input {
  width: 80%;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 5px;
  line-height: 30px;
  background: transparent;
  border: 1px solid rgb(42, 40, 40);
  border-radius: 4px;
  font-family: 'Regular';
  font-size: 20px;
  text-align: center;
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  background: transparent;
  border: 1px solid rgb(42, 40, 40);
  border-radius: 4px;
  font-size: 14px;
  font-family: 'Regular';
  padding: 10px;
}

.video_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  border-radius: 10px;
  overflow: hidden;
}

.link {
  display: flex;
  margin-top: 15px;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.link img {
  width: 120px;
  height: 80px;
}

.logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.logo {
  width: 32px;
  height: 32px;
}

.circle {
  border-radius: 50%;
}

.desctop {
  display: inline-block;
}

.fortech_logo {
  border-radius: 5px;
  width: 70px;
  height: 32px;
}

.error_message {
  font-size: 18px;
  color: rgb(192, 54, 54);
}

.telegram_link img {
  width: 50px;
  height: 50px;
}

.help_text-emoji {
  display: inline;
  font-size: 32px;
  height: 32px;
  width: 32px;
}

.help_text-action {
  display: none
}

.mobile {
  display: none;
  font-family: 'Comfortaa';
}

@media (max-width: 600px) {
  .mobile {
    display: block;
  }

  .help_text-action {
    display: flex;
    margin-top: 6px;
    gap: 6px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
  }

  .telegram_link {
    display: none;
  }

  .help_text {
    display: block;
  }

  .telegram_link {
    color: #fff;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 4px 20px 4px 20px;
    border-radius: 16px;
    height: 100%;
  }

  .phone-link {
    text-decoration: none;
    position: absolute;
    top: 50%;
    font-family: 'Comfortaa';
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }

  .app {
    padding: 60px 30px 30px 30px;
  }

  .desctop {
    display: none;
  }

  .body {
    align-items: normal;
    justify-content: start;
  }

  .link-mobile {
    background-color: blue;
    color: #fff;
    align-items: center;
    position: relative;
    width: 80px;
    padding: 4px 10px 4px 10px;
    border-radius: 20px;
  }

  .action-text {
    align-self: center;
  }

  body {
    background-image: none;
  }
}